export const statuses = {
  NEW: "1-05-new",
  MALWARE_AND_SECURITY: "1-04-malware-and-security",
  CLIENT_REPLIED: "1-04-client-replied",
  WEBSITE_TWEAKS: "1-03-website-tweaks",
  IN_PROGRESS: "1-03-in-progress",
  ON_HOLD: "1-02-on-hold",
  WAITING_FOR_REPLY: "1-01-waiting-for-reply",
  SCHEDULED: "1-00-scheduled",
  QUEUED: "1-00-queued",
  COMPLETE: "0-01-complete",
  CANCELLED: "0-01-cancelled",
  DELETED: "0-00-deleted"
};

export const teams = {
  MAINTENANCE: { key: "maintenance", label: "Maintenance" }
};

export const priorities = {
  GENERAL: "0-general",
  ADVANCED: "1-advanced",
  HIGH: "2-high"
};

export function getPriorityIcon(priority) {
  switch (priority) {
    case priorities.HIGH:
      return {
        icon: "fire",
        type: "is-danger",
        size: "is-small",
        title: "High priority task"
      };
    case priorities.ADVANCED:
      return {
        icon: "flask",
        type: "is-dark",
        size: "is-small",
        title: "Complex task"
      };
    default:
      return null;
  }
}
